<template>
  <v-container fluid>
    <h3 class="page-title">一番賞鎖定管理(延長時間)</h3>
    <v-row>
      <v-col cols="6" sm="3" md="2">
        <v-select
          label="店點"
          v-model="branch"
          :items="branches"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6" sm="6" md="3">
        <v-text-field
          v-model="keyword"
          label="關鍵字(一番賞名稱)"
          outlined
          dense
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30, -1],
          }"
          no-data-text="無可延長之線上一番賞"
        >
          <template v-slot:item.extend="{ item }">
            <v-btn color="primary" small @click="openDialog(item._id)">
              延長
            </v-btn>
          </template>
          <template v-slot:item.lockToUser="{ item }">
            {{
              `${mapUser[item.lockToUser]?.name}(${
                mapUser[item.lockToUser]?.phone
              })`
            }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="open" persistent width="400">
      <v-card>
        <v-card-title>延長一番賞鎖定時間</v-card-title>
        <v-card-text>
          <v-radio-group v-model="extendMinute">
            <v-radio
              v-for="n in extendMinuteOptions"
              :key="n"
              :label="`${n} 分鐘`"
              :value="n"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-btn text class="bt-text-large" color="error" @click="closeDialog">
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="bt-text-large"
            color="primary"
            @click="extendLockTimes(selectIchibanId)"
          >
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";
import util from "@/mixins/util.vue";

export default {
  name: "IchibanLock",
  mixins: [util],
  components: {},
  data: () => ({
    data: [],
    users: [],
    branch: null,
    keyword: "",
    open: false,
    extendMinute: 5,
    extendMinuteOptions: [5, 10, 30],
    selectIchibanId: null,
    headers: [
      { text: "店點", value: "branch", align: "left", sortable: false },
      { text: "編號", value: "key", align: "left", sortable: false },
      { text: "一番賞名稱", value: "name", align: "left", sortable: false },
      {
        text: "鎖定使用者",
        value: "lockToUser",
        align: "center",
        sortable: false,
      },
      { text: "鎖定時間", value: "lockTo", align: "center", sortable: true },
      { text: "延長", value: "extend", align: "center", sortable: false },
    ],
  }),
  computed: {
    filterData() {
      return this.data
        .filter((item) => !this.branch || item.branch === this.branch)
        .filter((item) => {
          return !this.keyword || item.name.includes(this.keyword);
        });
    },
    mapUser() {
      return _.keyBy(this.users, "userID");
    },
    branches() {
      if (this.accessBranch.length > 1) {
        return [{ text: "全部", value: null }, ...this.accessBranch];
      }
      return this.accessBranch;
    },
  },
  created() {
    this.getUsers();
    // this.$nextTick(() => {
    // this.ready = true;
    this.load();
    // });
  },
  methods: {
    async load() {
      this.$vloading.show();
      const { data } = await this.axios.get(`/ichiban/locks`);
      this.data = data.reverse();
      this.$vloading.hide();
    },
    async getUsers() {
      const { data } = await this.axios.get("/users", {
        params: { store: "@695yimoc", isVerified: true },
      });
      this.users = data.sort(this.sortDateDesc("createdAt"));
    },
    async extendLockTimes(id) {
      try {
        const confirm = await this.$dialog.confirm({
          text: "確定要手動延長？",
          waitForResult: true,
          showClose: false,
        });
        if (!confirm) return;

        this.$vloading.show();

        const { data } = await this.axios.put(
          `/ichiban/extend-lockTime/${id}`,
          { time: this.extendMinute * 60 }
        );
        await this.load();
        this.$vloading.hide();
        this.closeDialog();
        this.$toast.success("延長成功");
      } catch (error) {
        alert(error);
      }
    },
    openDialog(id) {
      this.extendMinute = 5;
      this.open = true;
      this.selectIchibanId = id;
    },
    closeDialog() {
      this.open = false;
      this.selectIchibanId = null;
    },
  },
};
</script>

<style scoped lang="scss"></style>
